<template>
  <div class="page">
    <div class="modal-block" v-if="showModal">
      <div class="modal-window">
        <div class="modal-header">
          <span class="modal-title">Заполните заявку</span>
          <span
            class="modal-close"
            @click="
              this.showModal = !this.showModal;
              this.modal_msg = '';
            "
          ></span>
        </div>
        <div class="modal-fields">
          <div class="field" v-for="field in modal_fields" :key="field">
            <label v-if="field.db_name !== 'partner_id'">{{
              field.name
            }}</label>
            <label
              v-if="
                field.db_name === 'partner_id' &&
                  modal_request_type !== 'Партнер'
              "
              >{{ field.name }}</label
            >
            <input
              v-if="
                field.db_name !== 'univer' &&
                  field.db_name !== 'region' &&
                  field.db_name !== 'partner_id'
              "
              type="text"
              :name="field.name"
              v-model="field.value"
              :disabled="
                field.db_name === 'region_other' &&
                  region_list.find(
                    (region) =>
                      region.id ===
                      modal_fields.find((item) => item.db_name === 'region')
                        .value
                  )?.name !== 'Другое'
              "
            />
            <div
              v-else-if="
                field.db_name === 'partner_id' &&
                  modal_request_type !== 'Партнер'
              "
            >
              <el-select
                v-model="selectedPartner"
                class="RDSelectField"
                placeholder="Выберите"
                size="large"
              >
                <el-option
                  v-for="item in filteredPartners"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>

            <div v-if="field.db_name === 'region'">
              <el-select
                v-model="field.value"
                class="RDSelectField"
                placeholder="Выберите"
                size="large"
                @change="changeUniverList(field.value)"
              >
                <el-option
                  v-for="item in region_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
            <div v-if="field.db_name === 'univer'">
              <el-select
                v-model="field.value"
                class="RDSelectField"
                placeholder="Выберите"
                size="large"
              >
                <el-option
                  v-for="item in univer_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </div>
        <span class="modal-msg">{{ modal_msg }}</span>
        <div class="modal-btn-block">
          <ButtonGradient
            @click="acceptRequest()"
            :ButtonText="loading ? 'Загрузка..' : 'Подтвердить заявку'"
            v-if="modal_request_status !== 'Рассмотрена'"
          />
          <ButtonGradientBorder
            @click="updateRequest()"
            :ButtonText="
              modal_request_status !== 'Рассмотрена'
                ? 'Сохранить и подтвердить позже'
                : 'Сохранить'
            "
          />
        </div>
      </div>
    </div>
    <div class="page-title">Новые заявки</div>
    <div class="page-content">
      <div class="SearchPanel">
        <div class="LeftSide">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19 7.17071C20.1652 7.58254 21 8.69378 21 10C21 11.3062 20.1652 12.4175 19 12.8293V19C19 19.5523 18.5523 20 18 20C17.4477 20 17 19.5523 17 19V12.8293C15.8348 12.4175 15 11.3062 15 10C15 8.69378 15.8348 7.58254 17 7.17071V5C17 4.44772 17.4477 4 18 4C18.5523 4 19 4.44772 19 5V7.17071ZM15 15C15 16.3062 14.1652 17.4175 13 17.8293V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V17.8293C9.83481 17.4175 9 16.3062 9 15C9 13.6938 9.83481 12.5825 11 12.1707V5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5V12.1707C14.1652 12.5825 15 13.6938 15 15ZM7 7.17071C8.16519 7.58254 9 8.69378 9 10C9 11.3062 8.16519 12.4175 7 12.8293V19C7 19.5523 6.55228 20 6 20C5.44772 20 5 19.5523 5 19V12.8293C3.83481 12.4175 3 11.3062 3 10C3 8.69378 3.83481 7.58254 5 7.17071V5C5 4.44772 5.44772 4 6 4C6.55228 4 7 4.44772 7 5V7.17071ZM6 11C6.55228 11 7 10.5523 7 10C7 9.44772 6.55228 9 6 9C5.44772 9 5 9.44772 5 10C5 10.5523 5.44772 11 6 11ZM18 11C18.5523 11 19 10.5523 19 10C19 9.44772 18.5523 9 18 9C17.4477 9 17 9.44772 17 10C17 10.5523 17.4477 11 18 11ZM12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16Z"
              fill="white"
            />
          </svg>
          <span
            class="show-filters-btn"
            @click="this.showFilters = !this.showFilters"
            >Фильтры</span
          >
          <span class="Notif">
            {{ filters.length }}
          </span>
        </div>
      </div>
      <div class="filters" v-if="showFilters">
        <span class="filters-title">Статус заявки</span>
        <div class="filters-items">
          <span
            @click="updFilters($event)"
            class="request-status yellow-color"
            :class="{
              'yellow-color-solid': this.filters.includes(
                'Ожидает рассмотрения'
              ),
            }"
            >Ожидает рассмотрения</span
          >
          <span
            @click="updFilters($event)"
            class="request-status green-color"
            :class="{
              'green-color-solid': this.filters.includes('Рассмотрена'),
            }"
            >Рассмотрена</span
          >
        </div>
      </div>
      <div class="requests">
        <div class="request" v-for="request in filteredRequests" :key="request">
          <div class="request-info">
            <span
              class="request-status"
              :class="{
                'yellow-color': request.status === 'Ожидает рассмотрения',
                'green-color': request.status === 'Рассмотрена',
              }"
              >{{ request.status }}</span
            >
            <span>
              <span class="request-type">{{ request.type }}</span>
              <span class="request-agent-name">
                &#8226; {{ request.f_name }} {{ request.l_name }}</span
              >
            </span>
            <span class="request-company-name">{{ request.company_name }}</span>
          </div>
          <div class="request-btn-block">
            <ButtonGradient
              @click="checkRequest(request)"
              v-if="request.status === 'Ожидает рассмотрения'"
              ButtonText="Рассмотреть заявку"
              class="request-btn"
            />
            <ButtonGradientBorder
              @click="checkRequest(request)"
              v-if="request.status === 'Рассмотрена'"
              ButtonText="Редактировать"
              class="request-btn"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";

import {
  getAllRequests,
  updateRequest,
  acceptRequest,
} from "@/api/company_requests.js";

import { findByEmail, getRegionAndUniverList } from "@/api/user.js";
import { getAll } from "@/api/company.js";

export default {
  name: "company-requests-page",
  components: { ButtonGradient, ButtonGradientBorder },
  data() {
    return {
      filters: ["Ожидает рассмотрения", "Рассмотрена"],
      loading: false,
      showFilters: false,
      showModal: false,
      requests: [],

      region_list: [],
      univer_list: [],

      partners_list: [],
      selectedPartner: null,
      region_other: "",
      modal_request_id: null,
      modal_request_status: null,
      modal_request_type: null,
      modal_msg: "",
      modal_fields: [
        { name: "Имя", value: "", db_name: "f_name" },
        { name: "Фамилия", value: "", db_name: "l_name" },

        { name: "Партнер", value: null, db_name: "partner_id" },

        { name: "Регион", value: "", db_name: "region" },
        { name: "Регион Другое", value: "", db_name: "region_other" },
        {
          name: "Наименование организации/учреждения",
          value: "",
          db_name: "company_name",
        },

        { name: "E-mail", value: "", db_name: "email" },
        { name: "Номер телефона", value: "", db_name: "phone" },
        { name: "ИНН организации", value: "", db_name: "inn" },
        { name: "Цель регистрации", value: "", db_name: "reg_goal" },
        { name: "Источник", value: "", db_name: "reg_source" },
        { name: "Отрасль", value: "", db_name: "industry" },
        { name: "Вид деятельности", value: "", db_name: "activityType" },

        {
          name: "Код партнера 1С (Необязательно)",
          value: "",
          db_name: "code_1c",
        },
      ],
    };
  },
  watch: {
    modal_request_id(newId, oldId) {
      if (newId === null) {
        getAllRequests().then((res) => {
          if (res.data.length > 0) this.requests = res.data;
        });
      }
    },
  },
  created() {
    getRegionAndUniverList().then((response) => {
      this.region_list = response.data;
    });
    getAllRequests().then((res) => {
      if (res.data.length > 0) this.requests = res.data;
    });

    getAll().then((response) => {
      this.partners_list = response.data;
    });
  },

  computed: {
    filteredPartners() {
      return this.partners_list.filter(
        (company_data) => company_data.type === "Партнер"
      );
    },

    filteredRequests() {
      return this.requests.filter((request) =>
        this.filters.includes(request.status)
      );
    },

    getUniversList() {
      let regionID = this.modal_fields.find((x) => {
        return x.db_name == "region";
      });

      if (regionID != null) {
        let region = this.region_list.find((x) => {
          return x.id == regionID.id;
        });

        if (region != null) {
          this.univer_list = region.univers;
        }
      }
    },
  },
  methods: {
    prepareDataForBack() {
      let dataForUpdate = {};
      this.modal_fields.forEach((field) => {
        // if (field.db_name === "region") {
        //   dataForUpdate[field.db_name] = this.requests.find(
        //     (request) => request.id === this.modal_request_id
        //   ).map_region?.id;
        // } else {
        dataForUpdate[field.db_name] = field.value;
        // }
      });
      return dataForUpdate;
    },
    updFilters(event) {
      const clickedFilter = this.filters.indexOf(event.target.textContent);
      clickedFilter === -1
        ? this.filters.push(event.target.textContent)
        : this.filters.splice(clickedFilter, 1);
    },
    checkRequest(request) {
      this.showModal = true;
      this.modal_request_id = request.id;
      this.modal_request_status = request.status;
      this.modal_request_type = request.type;

      if (request.type === "ВУЗ") {
        let array_for_add = [
          {
            name: "Должность",
            db_name: "position",
            insert_after: "company_name",
          },
          {
            name: "ВУЗ",
            db_name: "univer",
            insert_after: "region_other",
          },
          {
            name: "ВУЗ - Другое",
            db_name: "univer_other",
            insert_after: "univer",
          },
        ];

        for (let i of array_for_add) {
          const indexField = this.modal_fields.findIndex(
            (item) => item.db_name === i.db_name
          );

          if (indexField !== -1) {
            continue;
          }

          const index = this.modal_fields.findIndex(
            (item) => item.db_name === i.insert_after
          );

          if (index !== -1) {
            this.modal_fields.splice(index + 1, 0, {
              name: i.name,
              value: "",
              db_name: i.db_name,
            });
          }
        }
      } else {
        let array_for_remove = [
          {
            db_name: "position",
          },
          {
            db_name: "univer",
          },
          {
            db_name: "univer_other",
          },
        ];

        for (let i of array_for_remove) {
          const index = this.modal_fields.findIndex(
            (item) => item.db_name === i.db_name
          );

          if (index !== -1) {
            this.modal_fields.splice(index, 1);
          }
        }
      }

      this.modal_fields.forEach((field) => {
        if (field.db_name === "region") {
          field.value = request.map_region?.id ? request.map_region.id : "";

          this.changeUniverList(request.map_region.id);
        } else if (field.db_name === "univer") {
          field.value = parseInt(request.univer);
        } else {
          field.value = request[field.db_name];
        }

        return field;
      });
    },

    changeUniverList(regionID) {
      let region = this.region_list.find((x) => {
        return parseInt(x.id) == parseInt(regionID);
      });

      if (region != null) {
        this.univer_list = region.univers;
      }
    },

    updateRequest() {
      let dataForUpdate = this.prepareDataForBack();

      updateRequest(this.modal_request_id, dataForUpdate).finally(() => {
        this.modal_request_id = null;
        this.modal_request_status = null;
        this.modal_request_type = null;
        this.modal_fields.forEach((field) => (field.value = ""));
        this.showModal = false;
      });
    },

    acceptRequest() {
      this.loading = true;
      let dataForUpdate = this.prepareDataForBack();

      findByEmail({ email: dataForUpdate.email }).then((res) => {
        if (res.data !== null) {
          this.modal_msg =
            "Ошибка: Пользователь с таким email уже зарегистрирован в системе.";
          return;
        } else {
          let company_data = {
            name: dataForUpdate["company_name"],
            inn: dataForUpdate["inn"],
            partner_code: dataForUpdate["code_1c"],
            type: this.modal_request_type,
            reg_source: dataForUpdate["reg_source"],
            reg_goal: dataForUpdate["reg_goal"],
            region: dataForUpdate["region"],
            region_other: dataForUpdate["region_other"],
            industry: dataForUpdate["industry"],
            activityType: dataForUpdate["activityType"],
          };

          //Если это ВУЗ, нужно отправить поле "Должность" и универы
          if (this.modal_request_type === "ВУЗ") {
            let VUZArray = ["position", "univer", "univer_other"];

            for (let FieldName of VUZArray) {
              const index = this.modal_fields.findIndex(
                (item) => item.db_name === FieldName
              );
              if (index != null) {
                company_data[FieldName] = this.modal_fields[index].value;
              }
            }
          }

          let agent_data = {
            firstname: dataForUpdate["f_name"],
            lastname: dataForUpdate["l_name"],
            tel: dataForUpdate["phone"],
            email: dataForUpdate["email"],
          };
          if (this.modal_request_type !== "Партнер") {
            // сохраняем выбранного партнера
            company_data.partner_id = this.selectedPartner;
          }

          acceptRequest(this.modal_request_id, {
            company_data,
            agent_data,
          }).finally(() => {
            this.modal_request_id = null;
            this.modal_request_status = null;
            this.modal_fields.forEach((field) => (field.value = ""));
            this.selectedPartner = null; // обнуляем выбранного партнера
            this.showModal = false;
            this.loading = false;
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.field div {
  width: 100%;
}
.modal-msg {
  color: white;
  text-align: left;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}
.page {
  width: 75%;
  height: fit-content;

  display: flex;
  margin: 6.25rem auto;

  flex-direction: column;
}
.page-title {
  color: #e8e7ec;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: flex;
  justify-content: flex-start;
}

.page-content {
  margin-top: 3rem;
  width: 100%;
}

.request {
  margin-top: 0.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;

  width: 100%;
  min-height: 6rem;
  padding: 1.875rem;
  gap: 0.625rem;

  border-radius: 1.25rem;
  background: rgba(31, 30, 72, 0.8);
}

.request-btn-block {
  width: 30%;
  /* flex-basis: 375px; */
}
.request-btn {
  width: 100%;

  justify-content: center;
  flex-shrink: 0;
}

.request-info {
  width: fit-content;

  color: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 1;
  flex-basis: 500px;
}

.request-status {
  border: 1px solid;
  border-radius: 0.25rem;

  margin-bottom: 1rem;
  padding: 0.25rem 0.5rem;

  display: flex;
  align-items: flex-start;
  gap: 0.625rem;

  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.request-company-name {
  color: #fff;

  display: flex;

  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 1.45313rem */
}

.request-type {
  color: #fff;
  word-wrap: normal;

  /* h3 */
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.request-agent-name {
  color: #413e74;

  /* h3 */
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.green-color {
  color: #1dd185;
}

.yellow-color {
  color: #ffcf66;
}

.green-color-solid {
  background-color: #1dd185;
  border-color: #1dd185;
  color: #232323;
}

.yellow-color-solid {
  background-color: #ffcf66;
  color: #232323;
  border-color: #ffcf66;
}

.show-filters-btn {
  cursor: pointer;
}

.filters {
  position: absolute;

  padding: 1.625rem 1rem;
  margin-top: 0.5rem;

  max-width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;

  gap: 0.5rem;

  border-radius: 1.25rem;
  background: #1d1a42;

  box-shadow: 8px 0px 8px 0px rgba(31, 18, 35, 0.15);

  z-index: 5000;
}

.filters-title {
  overflow: hidden;
  color: #e8e7ec;
  text-overflow: ellipsis;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filters-items {
  display: flex;
  flex-wrap: wrap;

  cursor: pointer;
}

.filters-items span {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.filters-btn-block {
  display: flex;
  flex-wrap: wrap;
}

.filters-btn-block div {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.modal-block {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.modal-window {
  display: flex;
  flex-direction: column;

  height: fit-content;
  width: 35%;

  border-radius: 1.25rem;

  margin: 10% auto 10% auto;
  padding: 2.0625rem 2.375rem;

  background-image: url("./../../assets/img/profile/Stages/UpperBG.svg"),
    url("./../../assets/img/profile/Stages/BottomBG.svg");

  background-position: 0px 0px, 0px 100%;
  background-size: 100% auto;
  background-attachment: inherit;
  background-repeat: no-repeat;

  background-color: #201e48;
}

.modal-header {
  width: 100%;
  height: 1.1875rem;
  padding: 0px;
  border: none;
}

.modal-title {
  text-align: left;
  width: 16.1875rem;
  flex-shrink: 0;
  color: #fff;

  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.modal-close {
  position: relative;
  display: block;

  width: 1.1875rem;
  height: 1.1875rem;
  flex-shrink: 0;

  background: url("./../../assets/img/ModalCloseButton.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.modal-fields {
  margin: 1.88rem 0;
}
.field label {
  color: #e8e7ec;
  font-feature-settings: "calt" off;
  font-family: "Nunito Sans";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
  letter-spacing: -0.0175rem;

  display: flex;

  margin: 0.65rem 0 0.25rem 0;
}

.field input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;

  height: 3rem;
  width: 100%;

  color: #fff;

  /* text medium */
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 1.45313rem */

  padding: 0.8125rem 0.75rem;

  outline: none;

  border-radius: 0.25rem;
  border: 1px solid #413e74;
  background: linear-gradient(
    269deg,
    rgba(41, 37, 88, 0.54) 4.06%,
    rgba(38, 35, 83, 0.87) 42.35%,
    rgba(40, 38, 89, 0.5) 99.53%
  );
  box-shadow: -33px 45px 90px 0px #17122b;
}

.field input:disabled {
  opacity: 0.5;
}

.modal-btn-block {
  width: 100%;
}

.modal-btn-block div {
  width: 100%;
  margin-bottom: 0.5rem;
}

.SearchPanel {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 16px 32px;

  width: 100%;
  height: fit-content;
  min-height: 5rem;

  border-radius: 20px;
  background: rgba(32, 30, 72, 0.7);
  /* shadow */
  box-shadow: 8px 0px 8px 0px rgba(31, 18, 35, 0.15);
}
.SearchPanel > * {
  margin-top: auto;
  margin-bottom: auto;
}
.SearchPanel > .LeftSide {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}
.SearchPanel > .LeftSide > * {
  margin-top: auto;
  margin-bottom: auto;
}
.SearchPanel > .LeftSide > span {
  color: #e8e7ec;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SearchPanel > .LeftSide > .Notif {
  position: relative;

  padding: 2px 4px;

  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 130% */

  width: auto;
  height: 17px;

  aspect-ratio: 1 / 1;

  border-radius: 8px;
  background: #665bfd;

  text-align: center;
}
</style>

<style scoped>
@media only screen and (min-width: 1900px) {
  .modal-window {
    width: 40%;
  }
}
@media only screen and (max-width: 1200px) {
  .modal-window {
    width: 50%;
  }
  .request-btn-block {
    width: 100%;
  }
}
@media only screen and (max-width: 700px) {
  .modal-window {
    width: 95%;
    margin: 20% auto 20% auto;
  }
}
</style>
